import React, { ChangeEvent } from "react";
import Box from "@mui/material/Box";
import HoverableIconLabel from "./HoverableIconLabel";
import InputField from "./InputField";

interface InputProps {
  id: string;
  type: string;
  placeholder?: string;
  name: string;
  value: string;
  [key: string]: any;
}

interface LabeledInputProps {
  label: string;
  tooltip: string;
  inputProps: InputProps;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  shouldExtendWidth: boolean;
}

const LabeledInput: React.FC<LabeledInputProps> = ({
  label,
  tooltip,
  inputProps,
  onChange,
  onKeyDown,
  shouldExtendWidth,
}) => {
  return (
    <Box
      sx={{
        marginTop: "30px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HoverableIconLabel title={label} tooltip={tooltip} />
      <InputField
        {...inputProps}
        onChange={onChange}
        onKeyDown={onKeyDown}
        shouldExtendWidth={shouldExtendWidth}
      />
    </Box>
  );
};

export default LabeledInput;
