import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

interface HomeContentProps {
  isLoggedIn: boolean;
}

const HomeContent: React.FC<HomeContentProps> = ({ isLoggedIn }) => {
  const navigate = useNavigate();

  const handlePackageSubmissionForm = () => {
    if (isLoggedIn) {
      navigate("/package-submission-form");
    } else {
      navigate("/auth");
    }
  };

  return (
    <Box>
      <Box
        sx={{
          marginTop: "50px",
          marginBottom: "30px",
        }}
      >
        <Box
          sx={{
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "3rem",
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            Code Store
          </Typography>
          <Typography
            sx={{
              marginTop: "-10px",
              fontSize: "1.5rem",
              color: "#747474",
              fontFamily: "Inter",
              fontWeight: "300",
              textAlign: "center",
            }}
          >
            A marketplace for Swift Packages
          </Typography>
        </Box>
        <Button
          sx={{
            width: 250,
            marginTop: "30px",
            borderRadius: "3px",
            fontSize: "1rem",
          }}
          variant="contained"
          onClick={handlePackageSubmissionForm}
        >
          {isLoggedIn ? "List your package" : "List your package"}
        </Button>
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: "1.625rem",
            color: "black",
            fontFamily: "Inter",
            fontWeight: "600",
            textAlign: "left",
          }}
        >
          Sell your code
        </Typography>
        <Typography
          sx={{
            marginBottom: "40px",
            fontSize: "0.875rem",
            color: "black",
            fontFamily: "Inter",
            fontWeight: "300",
            textAlign: "left",
          }}
        >
          Sell your Swift packages to other iOS developers for a one-time price,
          or license it for recurring income.
        </Typography>

        <Typography
          sx={{
            fontSize: "1.625rem",
            color: "black",
            fontFamily: "Inter",
            fontWeight: "600",
            textAlign: "left",
          }}
        >
          Fast, Easy, Free
        </Typography>
        <Typography
          sx={{
            marginBottom: "40px",
            fontSize: "0.875rem",
            color: "black",
            fontFamily: "Inter",
            fontWeight: "300",
            textAlign: "left",
          }}
        >
          List and start selling in minutes. It's completely free. No payment
          info required.
        </Typography>

        <Typography
          sx={{
            fontSize: "1.625rem",
            color: "black",
            fontFamily: "Inter",
            fontWeight: "600",
            textAlign: "left",
          }}
        >
          Browse and Save
        </Typography>
        <Typography
          sx={{
            marginBottom: "40px",
            fontSize: "0.875rem",
            color: "black",
            fontFamily: "Inter",
            fontWeight: "300",
            textAlign: "left",
          }}
        >
          Save yourself time and money by exploring the marketplace for what
          other iOS devs have built for you.
        </Typography>
      </Box>
    </Box>
  );
};

export default HomeContent;
