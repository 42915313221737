import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const theme = {
  formControl: {
    width: 176,
    mt: -2,
  },
  inputLabel: {
    backgroundColor: "white",
    width: 60,
    position: "relative",
    top: 11,
    textAlign: "left",
    "&.MuiInputLabel-shrink": {
      top: 22,
    },
  },
  select: {
    height: 31,
  },
};

interface PriceModalProps {
  initialModel: string;
  initialPrice: string;
  initialStarts: string;
  initialSubscription: string;
  onClose: () => void;
  onSubmit: (data: {
    starts: string;
    model: string;
    price: string;
    subscription: string;
  }) => void;
}

interface SelectInputProps {
  label: string;
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  options: { value: string; label: string }[];
}

const SelectInput: React.FC<SelectInputProps> = ({
  label,
  value,
  onChange,
  options,
}) => (
  <Box sx={{ mb: 3.5 }}>
    <Typography
      sx={{
        textAlign: "left",
        fontFamily: "Inter",
        fontWeight: "600",
        marginBottom: "5px",
        backgroundColor: "transparent",
        fontSize: "16px",
      }}
    >
      {label}
    </Typography>
    <FormControl fullWidth sx={theme.formControl}>
      <InputLabel id={`${label.toLowerCase()}-label`} sx={theme.inputLabel}>
        Choose
      </InputLabel>
      <Select
        sx={theme.select}
        labelId={`${label.toLowerCase()}-label`}
        id={`${label.toLowerCase()}-select`}
        value={value}
        label={label}
        onChange={onChange}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

const PriceModal: React.FC<PriceModalProps> = ({
  initialModel,
  initialPrice,
  initialStarts,
  initialSubscription,
  onClose,
  onSubmit,
}) => {
  const [starts, setStarts] = useState<string>(initialStarts || "");
  const [model, setModel] = useState<string>(initialModel || "");
  const [price, setPrice] = useState<string>(initialPrice || "");
  const [subscription, setSubscription] = useState<string>(
    initialSubscription || ""
  );

  const showSubscriptionLength = model === "subscription";
  const showStarts = model === "subscription" || model === "pay_as_you_go";

  const generatePricingOptions = (
    start: number,
    step: number,
    count: number
  ) => {
    const options = [];
    for (let i = 0; i < count; i++) {
      const value = (start + i * step).toFixed(2);
      options.push({ value: `$${value}`, label: `$${value}` });
    }
    return options;
  };

  const staticPricing = generatePricingOptions(0.99, 1, 30);

  const startsInterval = [
    { value: "After 1 Week", label: "After 1 Week" },
    { value: "After 2 Weeks", label: "After 2 Weeks" },
    { value: "After 3 Weeks", label: "After 3 Weeks" },
    { value: "After 1 Month", label: "After 1 Month" },
  ];

  const subscriptionDuration = [
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "yearly", label: "Yearly" },
  ];

  const payAsYouGoData = [
    { value: "After 100 users", label: "After 100 users" },
    { value: "After 250 users", label: "After 250 users" },
    { value: "After 500 users", label: "After 500 users" },
    { value: "After 1000 users", label: "After 1000 users" },
    { value: "After 2500 users", label: "After 2500 users" },
    { value: "After 5000 users", label: "After 5000 users" },
    { value: "After 10000 users", label: "After 10000 users" },
  ];

  const handleModelChange = (event: SelectChangeEvent) => {
    setModel(event.target.value as string);
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/[^0-9.]/g, "");

    if (!/^\d*\.?\d{0,2}$/.test(value)) {
      return;
    }

    setPrice(value);
  };

  const handlePriceBlur = () => {
    if (price !== "" && !isNaN(Number(price))) {
      let numberValue = parseFloat(price);

      if (numberValue > 1000) {
        numberValue = 1000;
      } else if (numberValue < 0.0) {
        numberValue = 0.01;
      }

      setPrice(`$${numberValue.toFixed(2)}`);
    }
  };

  const handleSubmit = () => {
    if (model === "fixed_price") {
      if (price !== "") {
        setSubscription("");
        setStarts("");

        onSubmit({ starts, model, price, subscription: "lifetime" });
      } else {
        window.alert("Please select a Price.");
      }
    } else if (model === "subscription") {
      if (price !== "") {
        onSubmit({ starts, model, price, subscription });
      } else {
        window.alert("Please select a Price.");
      }
    } else if (model === "pay_as_you_go") {
      if (price !== "" && starts !== "") {
        onSubmit({ starts, model, price, subscription });
      } else {
        window.alert("Please select a Price and a Starting Interval.");
      }
    } else {
      window.alert("Please make a selection.");
    }
  };

  return (
    <Box sx={{ width: 440, background: "#ffffff", textAlign: "left" }}>
      <Typography
        variant="h6"
        component="h2"
        sx={{
          mb: 0,
          textAlign: "left",
          fontFamily: "Inter",
          fontWeight: "600",
        }}
      >
        Add a Pricing Model
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          mb: 3,
          textAlign: "left",
          fontFamily: "Inter",
          fontWeight: "400",
          fontSize: "14px",
        }}
      >
        You can add multiple pricing models to the same package.
      </Typography>

      <SelectInput
        label="Model"
        value={model}
        onChange={handleModelChange}
        options={[
          { value: "fixed_price", label: "Fixed Price" },
          { value: "pay_as_you_go", label: "Pay-as-you-go" },
          { value: "subscription", label: "Subscription" },
        ]}
      />

      {(model === "fixed_price" || model === "subscription") && (
        <SelectInput
          label="Price"
          value={price}
          onChange={(e) => setPrice(e.target.value as string)}
          options={staticPricing}
        />
      )}

      {model === "pay_as_you_go" && (
        <Box sx={{ mb: 3.5 }}>
          <Typography
            sx={{
              textAlign: "left",
              fontFamily: "Inter",
              fontWeight: "600",
              marginBottom: "15px",
              backgroundColor: "transparent",
              fontSize: "16px",
            }}
          >
            Price
          </Typography>
          <FormControl fullWidth sx={theme.formControl}>
            <TextField
              sx={theme.select}
              id="price-input"
              value={price}
              onChange={handlePriceChange}
              onBlur={handlePriceBlur}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9.]*",
                min: 0.01,
                max: 1000,
              }}
            />
          </FormControl>
        </Box>
      )}

      {showSubscriptionLength && (
        <SelectInput
          label="Subscription Length"
          value={subscription}
          onChange={(e) => setSubscription(e.target.value as string)}
          options={subscriptionDuration}
        />
      )}

      {showStarts && (
        <SelectInput
          label="Starts"
          value={starts}
          onChange={(e) => setStarts(e.target.value as string)}
          options={model === "pay_as_you_go" ? payAsYouGoData : startsInterval}
        />
      )}

      <Divider sx={{ marginTop: 4, marginBottom: 1 }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#ffffff",
        }}
      >
        <Button onClick={onClose} variant="outlined" sx={{ marginRight: 1 }}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          Confirm
        </Button>
      </Box>
    </Box>
  );
};

export default PriceModal;
