import React, { useState } from "react";
import { Box } from "@mui/material";
import SearchPackagesDropdown from "../common/SearchPackagesDropdown";
import { useAuth } from "../../context/AuthContext";
import HomeContent from "./HomeContent";
import { AnimatePresence, motion } from "framer-motion";

const Home: React.FC = () => {
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);

  const { isLoggedIn } = useAuth();

  return (
    <Box
      sx={{
        width: "70%",
        margin: "auto",
        marginTop: "5.69rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "3rem",
      }}
    >
      <SearchPackagesDropdown onSearchActive={setIsSearchActive} />
      <AnimatePresence>
        {!isSearchActive && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          >
            <HomeContent isLoggedIn={isLoggedIn} />
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default Home;
