import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/Help";
import CustomTooltip from "./CustomTooltip";

interface HoverableIconLabelProps {
  title: string;
  tooltip: string;
}

const HoverableIconLabel: React.FC<HoverableIconLabelProps> = ({
  title,
  tooltip,
}) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: "1rem",
          fontWeight: "600",
          textAlign: "left",
        }}
        mr={1}
      >
        {title}
      </Typography>

      <CustomTooltip title={tooltip} arrow>
        <IconButton
          sx={{
            height: "1.125rem",
            color: "lightgray",
            cursor: "pointer",
          }}
        >
          <HelpOutlineIcon />
        </IconButton>
      </CustomTooltip>
    </Box>
  );
};

export default HoverableIconLabel;
