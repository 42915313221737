import { 
  PricingModel, 
  PricingTimeline, 
  PricingInterval, 
  IPricing,
  PricingUserType

} from "@Pro-Stack-LLC/spm-shared/lib/models/pricing";

export interface PricingModelObject {
  id: string;
  model: string;
  price: string;
  starts: string;
  subscription: string;
}

export const convertStringToNumber = (price: string) => parseInt(price.replace(/[^0-9]/g, ''));

export const parseModelToEnum = (model: string): PricingModel => {
  switch (model) {
    case 'fixed_price':
    case 'subscription':
      return PricingModel.subscription;

    case 'pay_as_you_go':
      return PricingModel.userBased;

    default: 
      return PricingModel.subscription;
  }
};

export const parseIntervalToEnum = (interval: string): PricingInterval => {
  switch (interval) {
    case 'weekly':
      return PricingInterval.weekly;

    case 'monthly':
      return PricingInterval.monthly;

    case 'yearly':
      return PricingInterval.yearly;

    case 'lifetime':
      return PricingInterval.lifetime;

    default:
      return PricingInterval.monthly;
  }
};

export const parseStartAfterToEnum = (startAfter: string): { timeline: PricingTimeline, value: number } => {
  switch (startAfter) {
    case 'After 1 Week':
      return {
        timeline: PricingTimeline.week,
        value: 1
      };

    case 'After 2 Weeks':
      return {
        timeline: PricingTimeline.week,
        value: 2
      };

    case 'After 3 Weeks':
      return {
        timeline: PricingTimeline.week,
        value: 3
      };

    case 'After 1 Month':
      return {
        timeline: PricingTimeline.month,
        value: 1
      };

    default:
      return {
        timeline: PricingTimeline.month,
        value: 1
      }
  }
};

export const parseStartAfterToString = (timeline: PricingTimeline, value: number): string => {
  switch (timeline) {
    case PricingTimeline.week:
      return `After ${value} Week${value > 1 ? 's' : ''}`;

    case PricingTimeline.month:
      return `After ${value} Month${value > 1 ? 's' : ''}`;

    default:
      return `After ${value} Month${value > 1 ? 's' : ''}`;
  }
};

export const frontendToPackage = (pricingModel: PricingModelObject): Partial<IPricing> => {
  const cost = convertStringToNumber(pricingModel.price);
  const pricingInterval = parseIntervalToEnum(pricingModel.subscription);
  const pricingModelType: PricingModel = parseModelToEnum(pricingModel.model);
  const newPricing: Partial<IPricing> = {
    cost: cost,
    pricingModel: pricingModelType,
    pricingInterval: pricingInterval
  };

  if ((pricingModelType === PricingModel.subscription) && (pricingModel.starts)) {
    const { timeline, value } = parseStartAfterToEnum(pricingModel.starts);

    newPricing.pricingUserType = PricingUserType.afterX;
    newPricing.startAfter = timeline;
    newPricing.startAfterValue = value;
  }

  if (pricingModelType === PricingModel.userBased) {
    newPricing.pricingUserType = PricingUserType.perUser;
    newPricing.userAmount = convertStringToNumber(pricingModel.starts);
  }

  return newPricing;
};

export const packageToFrontend = (priceModel: IPricing): Partial<PricingModelObject> => {
  const data: Partial<PricingModelObject> = {};

  data.price = priceModel.cost ? `$${(priceModel.cost / 100).toFixed(2)}` : "$0.00";

  if (priceModel.pricingModel === PricingModel.subscription) {
    if (priceModel.pricingInterval === PricingInterval.lifetime) {
      data.model = "fixed_price";

    } else {
      data.model = "subscription";
      data.subscription = priceModel.pricingInterval;
    }
  }

  if (priceModel.userAmount) {
    data.model = "pay_as_you_go";
    data.starts = `${priceModel.userAmount}`;
  }

  if (priceModel.startAfter) {
    data.starts = parseStartAfterToString(priceModel.startAfter, priceModel.startAfterValue);
  }

  return data;
};