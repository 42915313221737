import React, { useEffect } from "react";
import { useAuth } from "./context/AuthContext";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthComponent from "./components/onboarding/AuthComponent";
import AppRoutes from "./AppRoutes";
import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
import Home from "./components/home/Home";
import LandingPageAbout from "./components/landingPage/landingPageLinks/LandingPageAbout";
import LandingPageFAQs from "./components/landingPage/landingPageLinks/LandingPageFAQS";
import LandingPageSupport from "./components/landingPage/landingPageLinks/LandingPageSupport";
import PageWrapper from "./components/common/PageWrapper";
import useScreenSize from "./hooks/UseScreenSize";
import MobileMessage from "./components/common/MobileMessage";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const App: React.FC = () => {
  const { isLoggedIn, loading } = useAuth();
  const isMobile = useScreenSize();

  useEffect(() => {
    if (!loading) {
      if (isLoggedIn) {
        console.log("Auth: 🟢");
      } else {
        console.log("Auth: 🔴");
      }
    }
  }, [isLoggedIn, loading]);

  if (isMobile) {
    return <MobileMessage />;
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <PageWrapper>
              <Home />
            </PageWrapper>
          }
        />
        <Route
          path="/auth"
          element={
            <PageWrapper>
              <AuthComponent />
            </PageWrapper>
          }
        />
        <Route
          path="/about"
          element={
            <PageWrapper>
              <LandingPageAbout />
            </PageWrapper>
          }
        />
        <Route
          path="/docs"
          element={
            <PageWrapper>
              <LandingPageFAQs />
            </PageWrapper>
          }
        />
        <Route
          path="/support"
          element={
            <PageWrapper>
              <LandingPageSupport />
            </PageWrapper>
          }
        />
        {isLoggedIn ? (
          <Route path="*" element={<AppRoutes />} />
        ) : (
          <Route path="*" element={<Navigate replace to="/" />} />
        )}
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
