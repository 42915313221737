import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Avatar,
  Link,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import logo from "../../assets/images/code-store-logo.png";
import SettingsDrawer from "../settingsDrawer/SettingsDrawer";
import { useAuth } from "../../context/AuthContext";

const Header: React.FC = () => {
  const { isLoggedIn, logout, userProfile } = useAuth();
  const [open, setOpen] = useState<boolean>(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleCloseDrawer = (itemClicked: string) => {
    setOpen(false);
    switch (itemClicked) {
      case "Profile":
        navigate("/user-profile");
        break;
      case "Package Live":
        navigate("/package-config");
        break;
      case "Manager":
        navigate("/package-manager");
        break;
      case "Logout":
        handleLogout();
        break;
      default:
        console.log("default");
    }
  };

  const toggleLogin = (): void => {
    navigate("/auth");
  };

  const handleHomeButton = (): void => {
    navigate("/");
  };

  const handleLogout = (): void => {
    setLogoutDialogOpen(true);
  };

  const handleLogoutConfirm = (): void => {
    setLogoutDialogOpen(false);
    logout();
  };

  const handleLogoutCancel = (): void => {
    setLogoutDialogOpen(false);
  };

  return (
    <Box
      sx={{ width: "100%", backgroundColor: "var(--spm-header-footer-color)" }}
    >
      <AppBar
        position="static"
        color="default"
        sx={{ boxShadow: "none", backgroundColor: "transparent" }}
      >
        <Toolbar
          sx={{
            justifyContent: "center",
            width: "100%",
            maxWidth: "80%",
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100px",
              marginLeft: 2,
            }}
          >
            <Button sx={{ marginRight: 4 }} onClick={handleHomeButton}>
              <Avatar
                src={logo}
                sx={{ width: 100, height: "auto" }}
                variant="square"
              />
            </Button>
          </Box>
          {!isMobile && (
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                marginRight: 2,
              }}
            >
              <Link component={RouterLink} to="/about" sx={linkStyle}>
                About
              </Link>
              <Link component={RouterLink} to="/docs" sx={linkStyle}>
                Docs
              </Link>
              <Link component={RouterLink} to="/support" sx={linkStyle}>
                Support
              </Link>
            </Box>
          )}
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100px",
            }}
          >
            <Button
              sx={{ textTransform: "none", color: "black" }}
              onClick={() => (isLoggedIn ? setOpen(true) : toggleLogin())}
            >
              {isLoggedIn ? (
                <Avatar
                  sx={{ width: 40, height: 40 }}
                  src={userProfile?.avatar}
                />
              ) : (
                "Sign In"
              )}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <SettingsDrawer open={open} onClose={handleCloseDrawer} />

      <Dialog
        open={logoutDialogOpen}
        onClose={handleLogoutCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">{"Leaving So Soon?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Logging out will close your session, but we’ll be here when you come
            back. Do you want to confirm?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleLogoutCancel}
            variant="outlined"
            color="secondary"
          >
            No
          </Button>
          <Button
            onClick={handleLogoutConfirm}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const linkStyle: SxProps<Theme> = {
  margin: "0 15px",
  textDecoration: "none",
  fontWeight: "regular",
  fontSize: "1rem",
  color: "black",
};

export default Header;
