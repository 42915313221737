import React from "react";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

interface StyledTooltipProps extends TooltipProps {
  className?: string;
}

const StyledTooltip = styled(({ className, ...props }: StyledTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background-color: rgba(47, 110, 195, 0.9);
    color: white;
    font-size: 0.8em;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    border-radius: 4px;
  }

  & .MuiTooltip-arrow {
    color: rgba(47, 110, 195, 0.85);
  }
`;

const CustomTooltip: React.FC<TooltipProps> = (props) => {
  return <StyledTooltip {...props} />;
};

export default CustomTooltip;
