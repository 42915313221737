import React from "react";
import { Button, TextField, Typography, createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";

const localTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#C6C6C6",
            },
            "&:hover fieldset": {
              borderColor: "#DE5D43",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#C6C6C6",
            },
            "& input": {
              backgroundColor: "white",
              color: "black",
              textAlign: "left",
              fontFamily: "Inter",
              "&::placeholder": {
                color: "#747474",
                fontStyle: "normal",
                fontSize: "1rem",
                textAlign: "left",
                fontFamily: "Inter",
                fontWeight: "300",
              },
            },
          },
        },
      },
    },
  },
});

interface LoginFormProps {
  onLogin: (event: React.FormEvent<HTMLFormElement>) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin }) => {
  return (
    <ThemeProvider theme={localTheme}>
      <form onSubmit={onLogin}>
        <Typography
          sx={{
            mb: 0,
            fontFamily: "Inter",
            fontWeight: "600",
            fontSize: "24px",
          }}
        >
          Login
        </Typography>
        <TextField
          label="Email"
          name="email"
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          name="password"
          type="password"
          variant="outlined"
          fullWidth
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            width: 250,
            marginTop: "15px",
            borderRadius: "3px",
            fontSize: "1rem",
            textTransform: "none",
          }}
        >
          Login
        </Button>
      </form>
    </ThemeProvider>
  );
};

export default LoginForm;
