import React, { useState } from "react";
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
} from "@mui/material";
import SimpleModal from "../common/SimpleModal";
import CancelSubscriptionModal from "./CancelSubscriptionModal";

interface PackageData {
  name: string;
  subscribedDate: string;
  pricePerMonth: string;
}

const packages: PackageData[] = [
  {
    name: "is-computer-on",
    subscribedDate: "Nov 15, 2024",
    pricePerMonth: "$9.99",
  },
  {
    name: "offshore-devs-pro-max",
    subscribedDate: "Mar 10, 2024",
    pricePerMonth: "$34.99",
  },
  {
    name: "david's-attention",
    subscribedDate: "",
    pricePerMonth: "Free trial for 492 more users then $49/month",
  },
];

const PurchasesManager: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleCancel = (packageName: string) => {
    setModalOpen(true);
  };

  const handleCloseModalPopup = () => {
    setModalOpen(false);
  };

  const handleCancelModalPopup = () => {
    setModalOpen(false);
  };

  return (
    <Box sx={{ width: "100%", marginTop: 5 }}>
      <Paper
        elevation={0}
        sx={{
          maxWidth: "70%",
          margin: "auto",
          overflow: "hidden",
        }}
      >
        <List>
          {packages.map((pkg, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <Button
                  variant="outlined"
                  onClick={() => handleCancel(pkg.name)}
                >
                  Cancel
                </Button>
              }
              divider
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <ListItemText
                sx={{ "& .MuiListItemText-secondary": { textAlign: "left" } }}
                primary={pkg.name}
                secondary={
                  pkg.subscribedDate
                    ? `Subscribed on: ${pkg.subscribedDate}`
                    : pkg.pricePerMonth
                }
              />
            </ListItem>
          ))}
          <SimpleModal open={modalOpen} onClose={() => setModalOpen(false)}>
            <CancelSubscriptionModal
              open={modalOpen}
              onClose={handleCloseModalPopup}
              onCancel={handleCancelModalPopup}
            />
          </SimpleModal>
        </List>
      </Paper>
    </Box>
  );
};

export default PurchasesManager;
