import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Avatar,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

interface ItemData {
  title: string;
  userhandle: string;
  downloads: number;
  rating: number;
  ratingCount: number;
  price: string;
  images: string[];
}

const dummyData: ItemData[] = [
  {
    title: "Custom Alert",
    userhandle: "v3rnalEquinox",
    downloads: 121,
    rating: 3,
    ratingCount: 184,
    price: "$4.99 + $0.99/mo + $0.01/u after 100",
    images: [
      "https://placekitten.com/200/300",
      "https://placekitten.com/200/300",
      "https://placekitten.com/200/300",
    ],
  },
  {
    title: "Rating Kit",
    userhandle: "pegasaurus",
    downloads: 5400,
    rating: 5,
    ratingCount: 645,
    price: "$19.99 + $11.99/mo + $1.06/u after 12",
    images: [
      "https://placekitten.com/200/300",
      "https://placekitten.com/200/300",
      "https://placekitten.com/200/300",
    ],
  },
];

interface PackageSearchProps {
  searchTerm: string;
}

const PackageSearch: React.FC<PackageSearchProps> = ({ searchTerm }) => {
  const filteredData = dummyData.filter(
    (item) =>
      item.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
      item.price.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "30%",
          marginTop: "-2rem",
          padding: 2,
        }}
      >
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel id="filter-by-label">Filter By</InputLabel>
          <Select labelId="filter-by-label" label="Filter By" defaultValue="">
            <MenuItem value="">None</MenuItem>
            <MenuItem value="subscription">Subscription</MenuItem>
            <MenuItem value="pay-as-you-go">Pay-as-you-go</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel id="sort-by-label">Sort By</InputLabel>
          <Select labelId="sort-by-label" label="Sort By" defaultValue="">
            <MenuItem value="star-rating">Star Rating</MenuItem>
            <MenuItem value="price">Price</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          overflow: "auto",
          paddingLeft: 2,
        }}
      >
        {filteredData.map((item, index) => (
          <Grid
            item
            xs={12}
            key={index}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              backgroundColor: "#F1F1F1",
              borderRadius: 2,
              height: "211px",
            }}
            marginTop={2}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                height: "95%",
                marginRight: 2,
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "1rem",
                    fontWeight: "600",
                    textAlign: "left",
                  }}
                  variant="subtitle1"
                  component="div"
                >
                  {item.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontWeight: "400",
                    textAlign: "left",
                  }}
                  variant="body2"
                  component="div"
                >{`${item.downloads} downloads`}</Typography>
                <Box
                  component="div"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {[...Array(Math.floor(item.rating))].map((_, i) => (
                    <StarIcon
                      sx={{
                        fontSize: "24px",
                      }}
                      key={i}
                    />
                  ))}
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "1rem",
                      fontWeight: "500",
                      textAlign: "left",
                      paddingLeft: "5px",
                      marginTop: "2px",
                    }}
                    component="span"
                  >
                    {item.ratingCount}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    marginTop: "3px",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Avatar
                    sx={{
                      height: "20px",
                      width: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "15px",
                    }}
                  >
                    <img
                      src="https://placekitten.com/200/300"
                      alt="Avatar"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Avatar>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "400",
                      textAlign: "left",
                      marginLeft: "11px",
                    }}
                    component="span"
                  >
                    {item.userhandle}
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "10px",
                    fontWeight: "400",
                    textAlign: "left",
                    marginTop: "4px",
                  }}
                  variant="body2"
                  component="div"
                >
                  {item.price}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Button
                    sx={{
                      height: "34px",
                      width: "107px",
                      borderRadius: "17px",
                      marginTop: "20px",
                    }}
                    variant="contained"
                  >
                    Download
                  </Button>
                </Box>
              </Box>
              {item.images.map((img, idx) => (
                <Box
                  key={idx}
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    backgroundColor: "#DEDEDE",
                    height: "100%",
                    borderRadius: "20px",
                    marginBottom: "6px",
                  }}
                >
                  <img
                    src={img}
                    alt={`${item.title} ${idx + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "20px",
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PackageSearch;
