import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, TextField, Button, Container } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SimpleModal from "../common/SimpleModal";
import PackageConfigModal from "./PackageConfigModal";
import PackageSuccessModal from "./PackageSuccessModal";

const PackageConfig: FC = () => {
  let navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [liveConfirmationOpen, setLiveConfirmationOpen] = useState(false);

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleLaunch = () => {
    setModalOpen(false);
    setLiveConfirmationOpen(true);
  };

  const handleDone = () => {
    setLiveConfirmationOpen(false);
    navigate("/");
  };

  return (
    <Container
      sx={{
        height: "100vh",
        width: "70%",
        display: "flex",
        alignItems: "top",
        justifyContent: "center",
        mt: "5rem",
      }}
    >
      <Box sx={{ borderRadius: "1rem", textAlign: "center", p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Your package is almost live!
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 4 }}>
          Just 3 steps left
        </Typography>

        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            Step 1
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
            To get paid, import the Swift Package Marketplace iOS SDK into your
            Swift package.
          </Typography>
          <TextField
            fullWidth
            defaultValue="github.com/swift-package-marketplace/ios-sdk.git"
            InputProps={{
              endAdornment: (
                <Button
                  onClick={() =>
                    handleCopy(
                      "github.com/swift-package-marketplace/ios-sdk.git"
                    )
                  }
                >
                  <ContentCopyIcon />
                </Button>
              ),
            }}
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            Step 2
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
            Call 'SwiftPackageMarketplace.configure(with: your-api-key)' in your
            package.
          </Typography>
          <TextField
            fullWidth
            defaultValue="DIYD-NDFG-AEYU-MJTG-5245"
            InputProps={{
              endAdornment: (
                <Button onClick={() => handleCopy("DIYD-NDFG-AEYU-MJTG-5245")}>
                  <ContentCopyIcon />
                </Button>
              ),
            }}
          />
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Step 3
          </Typography>
          <Typography variant="body1">
            Once step 1 and 2 are complete, come back and tap "Finish" to
            officially launch your listing.
          </Typography>
        </Box>

        <Button variant="contained" onClick={() => setModalOpen(true)}>
          Finish
        </Button>
        <SimpleModal open={modalOpen} onClose={() => setModalOpen(false)}>
          <PackageConfigModal onBack={handleCancel} onLaunch={handleLaunch} />
        </SimpleModal>

        <SimpleModal
          open={liveConfirmationOpen}
          onClose={() => setLiveConfirmationOpen(false)}
        >
          <PackageSuccessModal onDone={handleDone} />
        </SimpleModal>
      </Box>
    </Container>
  );
};

export default PackageConfig;
