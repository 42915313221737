import React, { useState, FC } from "react";
import {
  Box,
  Modal,
  Typography,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface CancelSubscriptionModalProps {
  open: boolean;
  onClose: () => void;
  onCancel: (reason?: string) => void;
}

const CancelSubscriptionModal: FC<CancelSubscriptionModalProps> = ({
  open,
  onClose,
  onCancel,
}) => {
  const [reason, setReason] = useState("");

  const handleCancel = () => {
    onCancel(reason);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <IconButton
          sx={{ position: "absolute", right: 8, top: 8 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" sx={{ textAlign: "center", mt: 2 }}>
          Are you sure about that?
        </Typography>
        <TextField
          label="Reason (optional)"
          fullWidth
          multiline
          rows={4}
          margin="normal"
          variant="outlined"
          value={reason}
          onChange={handleChange}
          sx={{ mt: 3 }}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCancel}
          sx={{ mt: 2 }}
        >
          Cancel Subscription
        </Button>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 1,
};

export default CancelSubscriptionModal;
