import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import { Box, Button } from "@mui/material";
import User from "@Pro-Stack-LLC/spm-shared/lib/sdk/user";
import { useAuth } from "../../context/AuthContext";

const AuthComponent: React.FC = () => {
  const [isLoginView, setIsLoginView] = useState(true);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    const username = event.currentTarget.email.value;
    const password = event.currentTarget.password.value;

    if (
      !username.includes("@") ||
      !username.includes(".") ||
      username.length < 2
    ) {
      alert("Please enter a valid email address.");
      return;
    }

    if (password.length < 2) {
      alert("Password must be at least 2 characters long.");
      return;
    }

    try {
      const response = await User.signin({ username, password });
      if (response.errors.length === 0) {
        login(response.data);
        navigate("/");
      } else {
        alert(`Signup failed: ${response.errors.join(", ")}`);
      }
    } catch (error) {
      alert(`An error occurred during signup: ${error}`);
    }
  };

  const handleRegister = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    const username = event.currentTarget.email.value;
    const password = event.currentTarget.password.value;

    if (
      !username.includes("@") ||
      !username.includes(".") ||
      username.length < 2
    ) {
      alert("Please enter a valid email address.");
      return;
    }

    if (password.length < 2) {
      alert("Password must be at least 2 characters long.");
      return;
    }

    try {
      const response = await User.signup({ username, password });
      if (response.errors.length === 0) {
        login(response.data);
        navigate("/");
      } else {
        console.error("Signup failed with error:", response.errors.join(", "));
        alert(`Signup failed: ${response.errors.join(", ")}`);
      }
    } catch (error) {
      console.error("An error occurred during signup:", error);
      alert(`An error occurred during signup: ${error}`);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box sx={{ width: 300, margin: "auto", mt: 20 }}>
        {isLoginView ? (
          <LoginForm onLogin={handleLogin} />
        ) : (
          <RegisterForm onRegister={handleRegister} />
        )}
        <Button
          onClick={() => setIsLoginView(!isLoginView)}
          sx={{ mt: 2, display: "block", width: "100%", textTransform: "none" }}
        >
          {isLoginView
            ? "Need an account? Register"
            : "Already have an account? Log in"}
        </Button>
      </Box>
    </Box>
  );
};

export default AuthComponent;
