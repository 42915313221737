import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/AccountCircle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Logout from "@mui/icons-material/Lock";

interface CustomDrawerProps {
  open: boolean;
  onClose: (itemClicked: string) => void;
}

const SettingsDrawer: React.FC<CustomDrawerProps> = ({ open, onClose }) => {
  const items = [
    {
      id: 1,
      text: "Profile",
      icon: <InboxIcon sx={{ color: "black", opacity: 0.6 }} />,
    },
  ];

  const logoutItem = {
    id: 4,
    text: "Logout",
    icon: <Logout sx={{ color: "black", opacity: 0.6 }} />,
  };

  return (
    <Drawer anchor="right" open={open} onClose={() => onClose("")}>
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <List sx={{ flexGrow: 1, marginTop: 0.2 }}>
          {items.map((item) => (
            <ListItem button key={item.id} onClick={() => onClose(item.text)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
              <ListItemIcon>
                <ChevronRightIcon />
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
        <List>
          <ListItem
            button
            key={logoutItem.id}
            onClick={() => onClose(logoutItem.text)}
            sx={{ mt: "auto" }}
          >
            <ListItemIcon>{logoutItem.icon}</ListItemIcon>
            <ListItemText
              primary={logoutItem.text}
              sx={{ color: "text.secondary" }}
            />
            <ListItemIcon>
              <ChevronRightIcon />
            </ListItemIcon>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default SettingsDrawer;
