import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Modal } from "@mui/material";
import { trackEvent } from "../../utils/MixpanelUtil";

const CookieConsentBanner: React.FC = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (consent === null) {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setOpen(false);
    trackEvent("cookie_consent_accepted");
  };

  const handleDeny = () => {
    localStorage.setItem("cookieConsent", "denied");
    setOpen(false);
    trackEvent("cookie_consent_denied");
  };

  return (
    <Modal
      open={open}
      onClose={() => {}}
      aria-labelledby="cookie-consent-title"
      aria-describedby="cookie-consent-description"
      BackdropProps={{
        sx: {
          backgroundColor: "rgba(0, 0, 0, 0.0)",
        },
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          bottom: 20,
          bgcolor: "background.paper",
          boxShadow: 10,
          borderRadius: "0.625rem",
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          maxWidth: "calc(100% - 32px)",
          width: 600,
          "& .MuiButton-root": {
            m: 1,
          },
        }}
      >
        <Typography
          id="cookie-consent-title"
          variant="h6"
          sx={{
            mb: 1,
            textAlign: "left",
            fontSize: "1rem",
            fontWeight: "600",
            fontFamily: "Inter",
          }}
        >
          Cookies help us help you!
        </Typography>
        <Typography
          id="cookie-consent-description"
          sx={{
            mb: 2,
            textAlign: "left",
            fontSize: "0.875rem",
            fontWeight: "400",
            fontFamily: "Inter",
          }}
        >
          We value your privacy. To enhance our site and improve your
          experience, we use cookies solely for analytics purposes. No personal
          data identifying individual users is collected. By continuing to use
          our site, you consent to our use of these cookies.
        </Typography>
        <Typography sx={{ fontSize: "0.875rem", mb: 1, textAlign: "left" }}>
          By clicking “Accept”, you consent to our use of cookies.
        </Typography>
        <Box>
          <Button
            sx={{ color: "#007AFF", borderColor: "#007AFF" }}
            variant="outlined"
            onClick={handleDeny}
          >
            Reject
          </Button>
          <Button
            sx={{
              backgroundColor: "#007AFF",
              "&:hover": { backgroundColor: "#005BBB" },
            }}
            variant="contained"
            onClick={handleAccept}
          >
            Accept
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CookieConsentBanner;
