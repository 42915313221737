import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Box, Button } from "@mui/material";

interface PriceBoxProps {
  id: string;
  model: string;
  price: string;
  starts: string;
  subscription: string;
  isEditMode: boolean;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
}

const PriceBox: React.FC<PriceBoxProps> = ({
  id,
  model,
  price,
  starts,
  subscription,
  isEditMode,
  onDelete,
  onEdit,
}) => {
  const [currentModel, setCurrentModel] = useState(model);
  const [currentPrice, setCurrentPrice] = useState(price);
  const [currentStarts, setCurrentStarts] = useState(starts);
  const [currentSubscription, setCurrentSubscription] = useState(subscription);

  useEffect(() => {
    setCurrentModel(model);
    setCurrentPrice(price);
    setCurrentStarts(starts);
    setCurrentSubscription(subscription);
  }, [model, price, starts, subscription]);

  const handleDeleteClick = () => {
    onDelete(id);
  };

  const handleEditClick = () => {
    onEdit(id);
  };

  const formatPrice = (price: string): string => {
    return price.startsWith("$") ? price : `$${price}`;
  };

  return (
    <Card
      sx={{
        backgroundColor: "#F1F1F1",
        boxShadow: "none",
        width: 444,
        borderRadius: 2,
      }}
    >
      <CardContent sx={{ padding: "16px 16px 8px" }}>
        <Box mb={0}>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "400",
              textAlign: "left",
              mb: 1.0,
            }}
          >
            <strong>Model:</strong>{" "}
            {currentModel === "fixed_price"
              ? "Fixed Price"
              : currentModel === "pay_as_you_go"
              ? "Pay-as-you-go"
              : "Subscription"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "400",
              textAlign: "left",
              mb: 1.0,
            }}
          >
            <strong>Price:</strong>{" "}
            {currentModel === "pay_as_you_go"
              ? formatPrice(currentPrice)
              : currentPrice}
          </Typography>
          {currentModel === "subscription" && currentStarts && (
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "left",
                mb: 1.0,
              }}
            >
              <strong>Starts:</strong> {currentStarts}
            </Typography>
          )}
          {currentModel === "pay_as_you_go" && currentStarts && (
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "left",
                mb: 1.0,
              }}
            >
              {isEditMode ? (
                <>
                  <strong>Starts: </strong>
                  {currentStarts.includes("After")
                    ? currentStarts
                    : `After ${currentStarts} users`}
                </>
              ) : (
                <>
                  <strong>Starts: </strong>
                  {currentStarts}
                </>
              )}
            </Typography>
          )}
          {currentModel === "subscription" && currentSubscription && (
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "left",
              }}
            >
              <strong>Subscription Length:</strong>{" "}
              {currentSubscription.charAt(0).toUpperCase() +
                currentSubscription.slice(1)}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "-15px",
          }}
        >
          <Button
            sx={{ color: "#747474" }}
            size="small"
            onClick={handleEditClick}
          >
            Edit
          </Button>
          <Button
            sx={{ color: "#747474" }}
            size="small"
            onClick={handleDeleteClick}
          >
            Delete
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PriceBox;
