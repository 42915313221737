import React, { ChangeEvent } from "react";
import { FormControl, OutlinedInput } from "@mui/material";

interface InputFieldProps {
  id: string;
  label?: string;
  type?: string;
  placeholder?: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  shouldExtendWidth: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  id,
  label,
  type = "text",
  placeholder,
  value,
  onChange,
  onKeyDown,
  shouldExtendWidth,
}) => {
  return (
    <FormControl fullWidth={shouldExtendWidth} sx={{ my: 2 }}>
      <OutlinedInput
        id={id}
        label={label}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        fullWidth={shouldExtendWidth}
      />
    </FormControl>
  );
};

export default InputField;
