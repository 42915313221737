import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Package from "@Pro-Stack-LLC/spm-shared/lib/sdk/package";
import { useAuth } from "../../context/AuthContext";
import { IPackage } from "@Pro-Stack-LLC/spm-shared/lib/models/package";
import { useNavigate } from "react-router-dom";
import { PackageStatus } from "@Pro-Stack-LLC/spm-shared/lib/models/package";

interface RequestConfig {
  token?: string;
  pagination?: {
    page: number;
    limit: number;
  };
}

const PackageManager: React.FC = () => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<IPackage | null>(null);
  const [packages, setPackages] = useState<IPackage[]>([]);
  const [emptyState, showEmptyState] = useState<boolean>(false);
  const navigate = useNavigate();
  const { token } = useAuth();

  const config: RequestConfig = useMemo(
    () => ({
      token: token,
      pagination: {
        page: 0,
        limit: 20,
      },
    }),
    [token]
  );

  const fetchData = useCallback(async () => {
    try {
      const response = await Package.getMine(config);

      if (response.errors.length === 0) {
        const filteredPackages = response.data.filter(
          (pkg: IPackage) => pkg.status !== PackageStatus.deleted
        );

        setPackages(filteredPackages);
        showEmptyState(filteredPackages.length === 0);
      } else {
        showEmptyState(true);
      }
    } catch (error) {
      showEmptyState(true);
    }
  }, [config]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDeleteClick = (pkg: IPackage) => {
    setSelectedPackage(pkg);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedPackage) {
      try {
        const updateData = {
          _id: selectedPackage._id,
          status: PackageStatus.deleted,
        };

        const response = await Package.update(updateData, config);

        if (response.errors.length === 0) {
          await fetchData();
        } else {
          console.error(response.errors.join(", "));
        }
      } catch (error) {
        console.error("An error occurred during package delete:", error);
      }

      setDeleteDialogOpen(false);
      setSelectedPackage(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setSelectedPackage(null);
  };

  const handlePackageUpload = () => {
    navigate("/package-submission-form");
  };

  const handleEditClick = (pkg: IPackage) => {
    navigate("/package-submission-form", { state: { pkg } });
  };

  return (
    <Box sx={{ width: "100%", marginTop: 5 }}>
      <Paper
        elevation={0}
        sx={{
          maxWidth: "70%",
          margin: "auto",
          overflow: "hidden",
          textAlign: "center",
        }}
      >
        {emptyState ? (
          <Button
            onClick={handlePackageUpload}
            variant="contained"
            sx={{ marginTop: 20 }}
          >
            Upload a package
          </Button>
        ) : (
          <List>
            {packages.map((pkg, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={() => handleEditClick(pkg)}
                      variant="outlined"
                      sx={{ marginRight: 2 }}
                    >
                      Edit
                    </Button>
                    <Box
                      sx={{
                        borderRadius: "5px",
                        border: "1px solid black",
                      }}
                    >
                      <IconButton
                        onClick={() => handleDeleteClick(pkg)}
                        sx={{
                          width: "2rem",
                          height: "2rem",
                        }}
                      >
                        <DeleteOutlineIcon sx={{ color: "red" }} />
                      </IconButton>
                    </Box>
                  </Box>
                }
                divider
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <ListItemText
                  sx={{
                    "& .MuiListItemText-secondary": {
                      textAlign: "left",
                      marginRight: "90px",
                    },
                  }}
                  primary={pkg.title}
                  secondary={pkg.title ? `${pkg.subtitle}` : pkg.title}
                  primaryTypographyProps={{
                    sx: {
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "black",
                    },
                  }}
                  secondaryTypographyProps={{
                    sx: {
                      textAlign: "left",
                      fontSize: "14px",
                      color: "rgb(0, 0, 0, 0.6)",
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          {"Are you sure you want to delete this package?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            This action cannot be undone. Please confirm your decision.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleDeleteCancel}
            variant="outlined"
            color="secondary"
          >
            No
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PackageManager;
