import React from "react";
import { Box, Typography } from "@mui/material";

const MobileMessage: React.FC = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        padding: "20px",
        textAlign: "center",
      }}
    >
      <Typography sx={{ fontWeight: "600", fontSize: "25px", mt: -12 }}>
        Please use a desktop device to access{" "}
        <span style={{ color: "#007AFF" }}>The Code Store</span> for the best
        experience.
      </Typography>
    </Box>
  );
};

export default MobileMessage;
