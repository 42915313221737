import React, { useEffect } from "react";
import { Box, Typography, Link, Container } from "@mui/material";
import Lottie from "lottie-react";
import animation from "../../../assets/animations/customer-service-animation.json";
import { trackEvent } from "../../../utils/MixpanelUtil";

const LandingPageSupport: React.FC = () => {
  useEffect(() => {
    trackEvent("promo_support_page_entry");
  });

  return (
    <Container
      maxWidth="md"
      sx={{ width: { xs: "90%", sm: "70%", xl: "70%" }, mx: "auto" }}
    >
      <Box
        sx={{
          mt: 7,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          sx={{
            mb: 3,
            fontSize: "1.625rem",
            fontWeight: "600",
            fontFamily: "Inter",
            textAlign: "left",
          }}
        >
          How can we help you?
        </Typography>
        <Typography
          sx={{
            mb: 2,
            fontSize: "0.875rem",
            fontWeight: "300",
            fontFamily: "Inter",
            textAlign: "left",
          }}
        >
          We take pride in being available.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mb: 2,
            fontSize: "0.875rem",
            fontWeight: "300",
            fontFamily: "Inter",
            textAlign: "left",
          }}
        >
          If you have any questions about how to create and list a package on
          the marketplace, or have any questions about the Code Store in
          general, please reach out to us via email at{" "}
          <Link
            href="mailto:team@codestore.ai"
            sx={{ color: "#DE5D43", textDecoration: "none", fontWeight: 600 }}
          >
            team@codestore.ai
          </Link>
          .
          <br />
          <br /> We would love to help you!
        </Typography>

        <Box
          sx={{
            mt: 4,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Lottie
            animationData={animation}
            loop={true}
            style={{ maxWidth: "500px", margin: "0 auto" }}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default LandingPageSupport;
