import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import User from "@Pro-Stack-LLC/spm-shared/lib/sdk/user";
import { IUser } from "@Pro-Stack-LLC/spm-shared/lib/models/user";

interface AuthContextType {
  isLoggedIn: boolean;
  loading: boolean;
  token?: string;
  userProfile?: IUser;
  login: (token: string) => void;
  logout: () => void;
  setUserProfile: (profile: IUser) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [userProfile, setUserProfile] = useState<IUser | undefined>(undefined);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
      setIsLoggedIn(true);
      fetchUserProfile(storedToken);
    } else {
      setLoading(false);
    }
  }, []);

  const fetchUserProfile = async (token: string) => {
    try {
      const config = { token };
      const response = await User.getCurrent(config);
      if (response.errors.length === 0) {
        setUserProfile(response.data);
      } else {
        console.error(
          "Fetch user profile failed with error:",
          response.errors.join(", ")
        );
      }
    } catch (error) {
      console.error("An error occurred during user profile fetch:", error);
    } finally {
      setLoading(false);
    }
  };

  const login = (token: string) => {
    localStorage.setItem("token", token);
    setToken(token);
    setIsLoggedIn(true);
    fetchUserProfile(token);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setToken(undefined);
    setIsLoggedIn(false);
    setUserProfile(undefined);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        loading,
        token,
        userProfile,
        login,
        logout,
        setUserProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(
      "useAuth must be used within an AuthProvider - check App.tsx file"
    );
  }
  return context;
};
