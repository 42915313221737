import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: "5px",
          padding: "10px 14px",
          textAlign: "left",
          fontFamily: "Inter, sans-serif",
          fontSize: "16px",
          color: "black",
          borderRadius: "5px",
          "&::placeholder": {
            color: "#747474",
            fontWeight: 400,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: "rgb(47, 110, 195)",
          color: "white",
          boxShadow: "none",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "rgb(47, 110, 195, 0.8)",
            boxShadow: "none",
          },
        },
        outlined: {
          borderColor: "rgb(47, 110, 195)",
          color: "rgb(47, 110, 195)",
          boxShadow: "none",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "rgb(47, 110, 195, 0.1)",
            boxShadow: "none",
            borderColor: "transparent",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
          "& .MuiInputBase-input": {
            textAlign: "left",
            color: "black",
            fontFamily: "Inter, sans-serif",
            fontSize: "16px",
          },
          "&$focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#C6C6C6",
            "&:hover": {
              borderColor: "rgb(47, 110, 195, 0.8)",
            },
            "&.Mui-focused": {
              borderColor: "rgb(47, 110, 195)",
              borderWidth: "2px",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(47, 110, 195, 0.8)",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: "5px",
            "& fieldset": {
              borderColor: "#C6C6C6",
              "&:hover": {
                borderColor: "rgb(47, 110, 195, 0.8)",
              },
              "&.Mui-focused": {
                borderColor: "rgb(47, 110, 195)",
                borderWidth: "2px",
              },
            },
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: "Inter, sans-serif",
          fontSize: "20px",
          fontWeight: 600,
          color: "#333",
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontFamily: "Inter, sans-serif",
          fontSize: "16px",
          color: "#666",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "8px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: "center",
        },
      },
    },
  },
});

export default theme;
