import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Paper,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  LinearProgress,
} from "@mui/material";

import SelectableImageFooter from "./SelectableImageFooter";
import LabeledInput from "../common/LabeledInput";
import PriceBox from "./PriceBox";
import SimpleModal from "../common/SimpleModal";
import PriceModal from "./PriceModal";
import HoverableIconLabel from "../common/HoverableIconLabel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { v4 as uuidv4 } from "uuid";
import Package from "@Pro-Stack-LLC/spm-shared/lib/sdk/package";
import { IPackage } from "@Pro-Stack-LLC/spm-shared/lib/models/package";
import { IPricing } from "@Pro-Stack-LLC/spm-shared/lib/models/pricing";

import { useAuth } from "../../context/AuthContext";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useLocation } from "react-router-dom";

import {
  PricingModelObject,
  packageToFrontend,
  frontendToPackage,
} from "../../utils/parsesUtils";

interface RequestConfig {
  token?: string;
}

interface PackageMeta {
  title: string;
  subTitle: string;
  url: string;
  trimmedKeywords: string[];
  imageUrls: string[];
  pricingModels: PricingModelObject[];
}

const PackageSubmissionForm: React.FC = () => {
  const [pricingModels, setPricingModels] = useState<PricingModelObject[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editingModelId, setEditingModelId] = useState<string | null>(null);
  const [initialModel, setInitialModel] = useState<string>("");
  const [initialPrice, setInitialPrice] = useState<string>("");
  const [initialStarts, setInitialStarts] = useState<string>("");
  const [initialSubscription, setInitialSubscription] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [subTitle, setsubTitle] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [keywords, setKeywords] = useState<string>("");
  const [images, setImages] = useState<(string | null)[]>([null, null, null]);
  const [uploading, setUploading] = useState<boolean>(false);

  const { token } = useAuth();
  const config: RequestConfig = {
    token: token,
  };

  const navigate = useNavigate();
  const location = useLocation();

  // IPackage pass from the Edit button on Package Manager
  const packageData = location.state?.pkg;

  useEffect(() => {
    if (!packageData) return;

    setTitle(packageData.title || "");
    setsubTitle(packageData.subtitle || "");
    setUrl(packageData.url || "");
    setKeywords(packageData.keywords.join(", ") || "");
    setImages(packageData.images || [null, null, null]);

    const pricingModels = packageData.pricing.map((priceModel: IPricing) => ({
      ...packageToFrontend(priceModel),
      id: priceModel.id || uuidv4(),
    }));

    setPricingModels(pricingModels);
  }, [packageData]);

  const handleImageUpdate = (index: number, newImage: string | null) => {
    const updatedImages = [...images];
    updatedImages[index] = newImage;
    setImages(updatedImages);
  };

  const handlePricingModelSubmit = (
    pricingModelData: Omit<PricingModelObject, "id">
  ) => {
    if (editingModelId) {
      setPricingModels((prevModels) =>
        prevModels.map((model) =>
          model.id === editingModelId
            ? { ...model, ...pricingModelData }
            : model
        )
      );
    } else {
      setPricingModels((prevModels) => [
        ...prevModels,
        { ...pricingModelData, id: uuidv4() },
      ]);
    }

    setModalOpen(false);
  };

  const handleDeletePricingModel = (modelId: string) => {
    setPricingModels(pricingModels.filter((model) => model.id !== modelId));
  };

  // TODO: Throwing in a hack for now
  const handleEditPackage = (modelId: string) => {
    const modelToEdit = pricingModels.find((model) => model.id === modelId);

    if (modelToEdit) {
      let formattedStarts = modelToEdit.starts;

      if (
        modelToEdit.model === "pay_as_you_go" &&
        !modelToEdit.starts.includes("After")
      ) {
        formattedStarts = `After ${modelToEdit.starts} users`;
      }

      setInitialModel(modelToEdit.model);
      setInitialPrice(modelToEdit.price);

      if (!!packageData) {
        setInitialStarts(formattedStarts);
      } else {
        setInitialStarts(modelToEdit.starts);
      }

      setInitialSubscription(modelToEdit.subscription);
      setEditingModelId(modelId);
      setModalOpen(true);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = event.target;

    const formattedValue = value.replace(/\s+/g, "");

    switch (id.toLowerCase()) {
      case "title":
        setTitle(value);
        break;

      case "url":
        setUrl(value);
        break;

      case "subtitle":
        setsubTitle(value);
        break;

      case "keywords":
        setKeywords(formattedValue);
        break;
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", ","];

    if (!allowedKeys.includes(event.key) && !/[a-zA-Z0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const uploadImageToCloudinary = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");

    const response = await fetch(
      `https://api.cloudinary.com/v1_1/dddtme8n5/image/upload`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data.secure_url;
    } else {
      throw new Error("Failed to upload avatar");
    }
  };

  const processPackage = (meta: PackageMeta) => {
    const pricing = meta.pricingModels.map((pricingModel) =>
      frontendToPackage(pricingModel)
    );
    const pkg: Partial<IPackage> = {
      title: meta.title,
      subtitle: meta.subTitle,
      url: meta.url,
      keywords: meta.trimmedKeywords,
      images: meta.imageUrls,
      pricing: pricing,
    };

    return pkg;
  };

  const handlePackageUpload = async (pkg: Partial<IPackage>) => {
    setLoading(true);

    let response: any;
    try {
      response = await Package.create(pkg, config);
    } catch (err: any) {
      const message = (err?.response?.data?.errors) ? 
        err.response.data.errors.join(', ') : 
        "An error occurred while uploading the package.";

      setLoading(false);

      window.alert(message);

      return;
    }

    if (response && response.errors && response.errors.length === 0) {
      setLoading(false);
      setSuccessDialogOpen(true);
    } else {
      setLoading(false);

      window.alert(
        "Package upload failed. Please try again and make sure the URL is unique."
      );
    }
  };

  const handlePackageEdit = async (pkg: Partial<IPackage>) => {
    setLoading(true);

    let response: any;
    try {
      response = await Package.update(pkg, config);
    } catch (err: any) {
      const message = (err?.response?.data?.errors) ? 
        err.response.data.errors.join(', ') : 
        "An error occurred while updating the package.";

      setLoading(false);

      window.alert(message);

      return;
    }

    if (response && response.errors && response.errors.length === 0) {
      setLoading(false);
      setSuccessDialogOpen(true);

    } else {
      setLoading(false);

      window.alert(
        "Package upload failed. Please try again and make sure the URL is unique."
      );
    }
  };

  const handleSubmitPackage = async () => {
    const trimmedTitle = title.trim();
    const trimmedSubTitle = subTitle.trim();
    const trimmedUrl = url.trim();
    const trimmedKeywords = keywords.trim();

    const hasAnyImages =
      images.length === 3 && images.every((img) => img !== null);
    const pricingModel = pricingModels.length > 0;

    if (!trimmedTitle) {
      window.alert("The title is missing.");
      return;
    }

    if (!trimmedSubTitle) {
      window.alert("The subtitle is missing.");
      return;
    }

    if (!trimmedUrl) {
      window.alert("The URL is missing.");
      return;
    }

    if (!trimmedKeywords) {
      window.alert("Keywords are missing.");
      return;
    }

    if (!hasAnyImages) {
      window.alert("Please select 3 images to better showcase your package.");
      return;
    }

    if (!pricingModel) {
      window.alert("Please add at least one pricing model.");
      return;
    }

    const keywordsArray = trimmedKeywords.split(",").map((kw) => kw.trim());

    let imageUrls: string[] = [];
    try {
      setUploading(true);

      imageUrls = await Promise.all(
        images
          .filter((image): image is string => image !== null)
          .map(async (image, index) => {
            const response = await fetch(image);
            const blob = await response.blob();
            const file = new File([blob], `image_${index}.jpg`, {
              type: blob.type,
            });

            return await uploadImageToCloudinary(file);
          })
      );

      setUploading(false);
    } catch (error) {
      setUploading(false);

      console.error("Error uploading images:", error);
      window.alert("Image upload failed. Please try again.");
    }

    const pkg = processPackage({
      title: trimmedTitle,
      subTitle: trimmedSubTitle,
      url: trimmedUrl,
      trimmedKeywords: keywordsArray,
      imageUrls: imageUrls,
      pricingModels: pricingModels,
    });

    if (packageData) {
      handlePackageEdit({
        _id: packageData._id,
        ...pkg,
      });
    } else {
      handlePackageUpload(pkg);
    }
  };

  const addIconDisabled = false;

  const addIconStyles = {
    width: 35,
    height: 35,
    marginLeft: 2,
    cursor: addIconDisabled ? "not-allowed" : "pointer",
    opacity: addIconDisabled ? 0.3 : 1,
  };

  const handleAddCircleClick = () => {
    if (!addIconDisabled) {
      setEditingModelId(null);
      setInitialModel("");
      setInitialPrice("");
      setInitialStarts("");
      setInitialSubscription("");
      setModalOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setSuccessDialogOpen(false);
    navigate("/package-manager");
  };

  return (
    <>
      {uploading && <LinearProgress />}
      <Paper
        sx={{
          width: "80%",
          margin: "auto",
          p: 4,
          mt: 8,
          mb: 8,
          boxShadow: "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <h1 style={{ fontSize: "1.625rem", fontWeight: 600 }}>
            Submit a Package
          </h1>
          <Box sx={{ display: "flex", gap: 1 }}>
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={handleSubmitPackage}
            >
              {packageData ? "Update" : "Save"}
            </LoadingButton>
          </Box>
        </Box>

        <h2
          style={{
            textAlign: "left",
            marginTop: 3,
            fontSize: "1.25rem",
            fontWeight: 600,
          }}
        >
          Package Info
        </h2>
        <form>
          <LabeledInput
            label="Title"
            tooltip="This is the title of your package as it will appear on Code Store."
            inputProps={{
              id: "title",
              type: "text",
              placeholder: "Enter Title",
              name: "title",
              value: title,
            }}
            onChange={handleChange}
            shouldExtendWidth={true}
          />
          <LabeledInput
            label="Subtitle"
            tooltip="This is the subtitle as it will appear on Code Store."
            inputProps={{
              id: "subtitle",
              type: "text",
              placeholder: "Enter Subtitle",
              name: "subtitle",
              value: subTitle,
            }}
            onChange={handleChange}
            shouldExtendWidth={true}
          />
          <LabeledInput
            label="URL"
            tooltip="This is the GitHub URL that points to your package."
            inputProps={{
              id: "url",
              type: "text",
              placeholder: "Enter GitHub URL",
              name: "url",
              value: url,
            }}
            onChange={handleChange}
            shouldExtendWidth={true}
          />
          <LabeledInput
            label="Keywords"
            tooltip="Enter in keywords specific to your package name, separated by a comma."
            inputProps={{
              id: "keywords",
              type: "text",
              placeholder: "Enter Keywords ie. Popup, Modal, Overlay, Tooltip",
              name: "url",
              value: keywords.replace(/\s+/g, ""),
            }}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            shouldExtendWidth={true}
          />
        </form>

        <SelectableImageFooter
          images={images}
          onImageUpdate={handleImageUpdate}
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: 3,
          }}
        >
          <HoverableIconLabel
            title="Pricing Models"
            tooltip="Select the package that best suits your needs."
          />

          <AddCircleIcon
            color="primary"
            sx={addIconStyles}
            onClick={handleAddCircleClick}
          />
        </Box>

        <SimpleModal open={modalOpen} onClose={() => setModalOpen(false)}>
          <PriceModal
            onClose={() => setModalOpen(false)}
            onSubmit={handlePricingModelSubmit}
            initialModel={initialModel}
            initialPrice={initialPrice}
            initialStarts={initialStarts}
            initialSubscription={initialSubscription}
          />
        </SimpleModal>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: 3,
            gap: 1,
          }}
        >
          {pricingModels.map((model) => (
            <PriceBox
              key={model.id}
              id={model.id}
              model={model.model}
              price={model.price}
              starts={model.starts}
              subscription={model.subscription}
              onDelete={handleDeletePricingModel}
              onEdit={handleEditPackage}
              isEditMode={!!packageData}
            />
          ))}
        </Box>
      </Paper>

      <Dialog
        open={successDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Package Uploaded Successfully!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Hooray! Your package has taken off and is successfully uploaded.
            Time to celebrate!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box>
            <Button
              variant="contained"
              onClick={handleCloseDialog}
              autoFocus
              sx={{ textAlign: "center" }}
            >
              Got it
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PackageSubmissionForm;
