import React, { useEffect } from "react";
import { Box, Container, Typography, Avatar } from "@mui/material";
import ArcodiaAvatar from "../../../assets/faces/arcodia.jpeg";
import WrightAvatar from "../../../assets/faces/wright.jpeg";
import SolbergAvatar from "../../../assets/faces/solberg.jpg";
import aboutUsImage from "../../../assets/faces/team-about-us.png";
import { trackEvent } from "../../../utils/MixpanelUtil";

interface TeamMember {
  name: string;
  title: string;
  location: string;
  bio: string;
  avatar: string;
  quote: string;
  skills: string;
}

const teamMembers: TeamMember[] = [
  {
    name: "Charlie Arcodia",
    title: "Frontend Developer",
    location: "Colorado, USA",
    bio: "With over 15 years of experience as a software engineer, Charlie has dedicated his career to not only developing innovative software solutions but also to the art of building and structuring high-performing teams. His expertise extends into the realm of 3D technologies, where he has significantly advanced solutions for tracking in three-dimensional spaces, focusing on enhancing interactions and functionalities within the 3D world. This pioneering work in 3D and Z-axis solutions underscores his commitment to pushing the boundaries of technology and its applications. In 2019, Charlie's innovative contributions, particularly with the startup Tripcents, were recognized at Techstars, further solidifying his reputation as a forward-thinking developer and entrepreneur in the tech community. His passion for startups is not just about creating technology but also about nurturing the teams behind the innovation. Charlie is known for his ability to set up and manage efficient teams, a skill that has been instrumental in the successful launch and growth of various startups.",
    avatar: ArcodiaAvatar,
    quote:
      "In my spare time, I enjoy a little thing called ‘reinventing industries’.",
    skills:
      "Charlie's passion for startups has propelled him into the realms of AI-driven gaming and the groundbreaking fusion of AAA esports titles with augmented reality. He's mastered mobile development across iOS and Android, ensuring engaging user experiences in a variety of applications. Beyond the frontend, Charlie has engineered complex mixed reality solutions, laying the foundation for robust government contracts in the B2G space along with local and federal Combined Arms programs with the Hololens.",
  },
  {
    name: "Steven Wright",
    title: "Backend Developer",
    location: "New Hampshire, USA",
    bio: "Steven has been writing software and exploring computers since the age of 17. After starting his own freelancing business in 2012 working with clients of all sizes Steven discovered his passion for working with startups and has spent the past several years in lead roles at a number of startups. Having worked with hundreds of programming languages and frameworks during his career Steven is able to quickly pick up new technologies and roll out solutions for his clients and take on leadership roles to help guide the development process from start to finish. Steven loves networking and security and spends a lot of his free time exploring modern network architectures, protocols and more!",
    avatar: WrightAvatar,
    quote:
      "Have you met any users lately? They consistently ruin every piece of software I've ever written. - Jeff Atwood",
    skills:
      "Specializing in all things low level Steven's primary focus is architecting systems and developing backend services to support frontend work. Designing and developing complex cloud systems and building the software that runs on them. Since Steven is also a cloud security expert he's a great resource for existing teams to improve their cloud services architecture and codebase security.",
  },
  {
    name: "Eric Solberg",
    title: "UI/UX Designer",
    location: "Arizona, USA",
    bio: "Eric discovered his passion for iOS development after he decided to teach himself to code because he wanted to build personal apps. He launched six of them on the App Store, two of which gained over 100,000 downloads each in their first year that he later sold. During this, Eric also began a 2 year freelancing and tutoring tenure in iOS development, receiving 5-star reviews from every client and student he worked with. Later, Eric worked as a full-time iOS developer in the corporate world, before he was hired once again by Charlie Arcodia (whom he had previously worked for in a past freelancing contract) to take over the lead iOS engineer role at Kwiks, a social media startup. Now the Kwiks development team has joined forces once again to form Pro Stack Development, a premier software development company.",
    avatar: SolbergAvatar,
    quote:
      "Developing an app is like building a house. It can look pretty on the outside, but if the foundations aren't solid, it will eventually collapse.",
    skills:
      "As a full-stack iOS developer, Eric excels at everything within the realm of iOS development. He is a creative problem solver that takes pride in writing very high quality code that is clean, modular, and scalable. This means new features can be added quickly, and - like a clean house - bugs have nowhere to hide.",
  },
];
const LandingPageAbout: React.FC = () => {
  useEffect(() => {
    trackEvent("promo_about_page_entry");
  });

  return (
    <Container
      sx={{
        width: {
          xs: "90%",
          sm: "90%",
          md: "70%",
          lg: "70%",
          xl: "70%",
        },
        marginTop: 5,
        marginBottom: 12,
      }}
      maxWidth="lg"
    >
      <Typography
        sx={{
          textAlign: "left",
          fontSize: "1.25rem",
          fontWeight: "600",
          fontFamily: "Inter",
        }}
        variant="h4"
        gutterBottom
      >
        The Team
      </Typography>
      <Typography
        sx={{
          textAlign: "left",
          fontSize: "0.875rem",
          fontWeight: "300",
          fontFamily: "Inter",
        }}
        paragraph
      >
        Meet the founding members of the Code Store –– Charlie Arcodia, Steven
        Wright, and Eric Solberg.
      </Typography>
      <Typography
        sx={{
          textAlign: "left",
          fontSize: "0.875rem",
          fontWeight: "300",
          fontFamily: "Inter",
        }}
        paragraph
      >
        The three met a few years ago while working on a client project, more
        recently formed the core development team for Kwiks, Inc., and have
        recently joined forces to form Pro Stack, LLC – a premier software
        development company.
      </Typography>
      <Typography
        sx={{
          textAlign: "left",
          fontSize: "0.875rem",
          fontWeight: "300",
          fontFamily: "Inter",
        }}
        paragraph
      >
        Each has an important role to play in the creation of the Code Store.
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          px: 1,
        }}
      >
        <Avatar
          src={aboutUsImage}
          sx={{
            width: "100%",
            maxWidth: "30.8rem",
            height: "auto",
            borderRadius: 0,
            mt: 1,
          }}
        />
      </Box>

      {/* <Grid sx={{ marginTop: 2 }} container spacing={2} justifyContent="center">
        {teamMembers.map((member, index) => (
          <Grid item key={index}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <Avatar
                src={member.avatar}
                alt={member.name}
                sx={{
                  width: 150,
                  height: 150,
                  mb: 2,
                  filter: "grayscale(100%)",
                }}
              />
              <Typography
                sx={{
                  textAlign: "left",
                  fontSize: "0.875rem",
                  fontWeight: "700",
                  fontFamily: "Inter",
                }}
                variant="h6"
              >
                {member.name}
              </Typography>
              <Typography
                sx={{
                  textAlign: "left",
                  fontSize: "0.875rem",
                  fontWeight: "300",
                  fontFamily: "Inter",
                  color: "gray",
                }}
                variant="subtitle1"
                color="textSecondary"
              >
                {member.title}
              </Typography>
              <Typography
                sx={{
                  textAlign: "left",
                  fontSize: "0.875rem",
                  fontWeight: "300",
                  fontFamily: "Inter",
                  color: "gray",
                }}
                variant="body2"
                color="textSecondary"
              >
                {member.location}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid> */}
      {teamMembers.map((member, index) => (
        <Box key={index} sx={{ mt: 4 }}>
          <Typography
            sx={{
              textAlign: "left",
              fontSize: "1rem",
              fontWeight: "600",
              fontFamily: "Inter",
            }}
            variant="h5"
            gutterBottom
          >
            {member.name}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              fontStyle: "italic",
              textAlign: "left",
              fontSize: "0.875rem",
              fontWeight: "300",
              fontFamily: "Inter",
            }}
          >
            "{member.quote}"
          </Typography>
          <Typography
            variant="h6"
            component="p"
            sx={{
              mt: 2,
              textAlign: "left",
              fontSize: "0.875rem",
              fontWeight: "600",
              fontFamily: "Inter",
            }}
          >
            Biography
          </Typography>
          <Typography
            sx={{
              mt: 2,
              textAlign: "left",
              fontSize: "0.875rem",
              fontWeight: "300",
              fontFamily: "Inter",
            }}
            variant="body1"
          >
            {member.bio}
          </Typography>
          <Typography
            variant="h6"
            component="p"
            sx={{
              mt: 2,
              textAlign: "left",
              fontSize: "0.875rem",
              fontWeight: "600",
              fontFamily: "Inter",
            }}
          >
            Skills
          </Typography>
          <Typography
            sx={{
              mt: 2,
              textAlign: "left",
              fontSize: "0.875rem",
              fontWeight: "300",
              fontFamily: "Inter",
            }}
            variant="body1"
          >
            {member.skills}
          </Typography>
        </Box>
      ))}
    </Container>
  );
};

export default LandingPageAbout;
