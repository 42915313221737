import { useState } from "react";
// import TextField from "@mui/material/TextField";
// import InputAdornment from "@mui/material/InputAdornment";
// import SearchIcon from "@mui/icons-material/Search";
// import CloseIcon from "@mui/icons-material/Close";
// import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import PackageSearch from "./PackageSearch";
import { AnimatePresence, motion } from "framer-motion";

interface SearchPackagesDropDownProps {
  onSearchActive: (active: boolean) => void;
}

const SearchPackagesDropdown: React.FC<SearchPackagesDropDownProps> = ({
  onSearchActive,
}) => {
  const [searchTerm] = useState<string>("");

  // const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
  //   const value = event.target.value;
  //   setSearchTerm(value);
  //   onSearchActive(value.length > 0);
  // };

  // const clearInput = (): void => {
  //   setSearchTerm("");
  //   onSearchActive(false);
  // };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      {/* <TextField
        value={searchTerm}
        onChange={handleInputChange}
        placeholder="Search Packages"
        label={searchTerm ? "Search Packages" : ""}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment sx={{ ml: 1, width: "25px" }} position="start">
              <SearchIcon sx={{ color: "rgba(0, 0, 0, 0.3)" }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment sx={{ mr: 1 }} position="end">
              <IconButton
                onClick={clearInput}
                edge="end"
                sx={{ visibility: searchTerm ? "visible" : "hidden" }}
              >
                <CloseIcon sx={{ color: "rgba(0, 0, 0, 0.3)" }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        variant="outlined"
        sx={{ width: "100%", margin: "0 auto" }}
      /> */}
      {searchTerm && (
        <Box sx={{ width: "100%", marginTop: "2rem" }}>
          <AnimatePresence>
            <motion.div
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -50, opacity: 0 }}
              transition={{ duration: 0.1 }}
            >
              <PackageSearch searchTerm={searchTerm} />
            </motion.div>
          </AnimatePresence>
        </Box>
      )}
    </Box>
  );
};

export default SearchPackagesDropdown;
