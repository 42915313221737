import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

// Define the props interface
interface SimpleModalProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

const SimpleModal: React.FC<SimpleModalProps> = ({
  children,
  open,
  onClose,
}) => {
  const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 486,
    bgcolor: "background.paper",
    boxShadow: 5,
    pt: 4,
    pb: 2,
    pl: 3,
    pr: 3,
    borderRadius: 2,
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box sx={style}>{children}</Box>
    </Modal>
  );
};

export default SimpleModal;

/* QUICK USE COPY
  import React, { useState } from "react";
  import Button from "@mui/material/Button";
  import SimpleModal from "./SimpleModal"; // Adjust the import path accordingly

  const Example = () => {
    const [modalOpen, setModalOpen] = useState(false);
    return (
      <div>
        <Button variant="contained" onClick={() => setModalOpen(true)}>
          Open Modal
        </Button>
        <SimpleModal open={modalOpen} onClose={() => setModalOpen(false)}>
          <div>content</div>
        </SimpleModal>
      </div>
    );
  };

  export default Example;
*/
