import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddCircle from "@mui/icons-material/AddCircle";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import HoverableIconLabel from "../common/HoverableIconLabel";

interface ImageBoxProps {
  onImageSelected: () => void;
  src: string | null;
}

const ImageBox: React.FC<ImageBoxProps> = ({ onImageSelected, src }) => {
  return (
    <Paper
      sx={{
        width: "150px",
        height: "262px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        borderRadius: "20px",
        overflow: "hidden",
        border: "1px solid #C6C6C6",
        backgroundColor: "#FAFAFA",
        boxShadow: "none",
      }}
      onClick={onImageSelected}
    >
      {src ? (
        <img
          src={src}
          alt="Uploaded"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      ) : (
        <IconButton size="large">
          <AddCircle color="primary" fontSize="large" />
        </IconButton>
      )}
    </Paper>
  );
};

interface SelectableImageFooterProps {
  images: (string | null)[];
  onImageUpdate: (index: number, newImage: string | null) => void;
}

const SelectableImageFooter: React.FC<SelectableImageFooterProps> = ({
  images,
  onImageUpdate,
}) => {
  const handleImageSelection = (index: number) => () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";

    fileInput.onchange = (e: Event) => {
      const target = e.target as HTMLInputElement;
      const file = target.files ? target.files[0] : null;

      if (file) {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          onImageUpdate(index, e.target?.result as string | null);
        };
        reader.readAsDataURL(file);
      }
    };

    fileInput.click();
  };

  return (
    <Box mt={3}>
      <Box mb={2.2}>
        <HoverableIconLabel
          title="Images"
          tooltip="Upload three images to showcase the contents of your package."
        />
      </Box>
      <Grid container spacing={2}>
        {images.map((src, index) => (
          <Grid item key={index}>
            <ImageBox src={src} onImageSelected={handleImageSelection(index)} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SelectableImageFooter;
