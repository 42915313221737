import { FC } from "react";
import { Box, Typography, Button } from "@mui/material";
import Lottie from "lottie-react";
import celebrationAnimation from "../../assets/animations/success-animation.json";

interface PackageSuccessProps {
  onDone: () => void;
}

const PackageSuccessModal: FC<PackageSuccessProps> = ({ onDone }) => {
  return (
    <Box sx={containerStyle}>
      <Typography
        variant="h5"
        component="h2"
        sx={{ fontWeight: "bold", mb: 2 }}
      >
        Congratulations!
      </Typography>
      <Typography variant="h6" sx={{ mb: 4 }}>
        Your listing is officially LIVE
      </Typography>
      <Box sx={{ height: 200, mb: 4 }}>
        <Lottie
          animationData={celebrationAnimation}
          loop={true}
          autoplay={true}
        />
      </Box>
      <Button variant="contained" onClick={onDone}>
        Done
      </Button>
    </Box>
  );
};

export default PackageSuccessModal;

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 1,
  maxWidth: 500,
  textAlign: "center",
  "&:focus-visible": {
    outline: "none",
  },
};
