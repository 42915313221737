import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import PackageSubmissionForm from "./components/packageSubmission/PackageSubmissionForm";
import Home from "./components/home/Home";
import PackageConfig from "./components/packageConfig/PackageConfig";
import UserProfile from "./components/userProfile/UserProfile";
import PackageManager from "./components/packageManager/PackageManager";
import PurchasesManager from "./components/packageManager/PurchasesManager";
import PageWrapper from "./components/common/PageWrapper";
import MobileMessage from "./components/common/MobileMessage";
import useScreenSize from "./hooks/UseScreenSize";

const AppRoutes: React.FC = () => {
  const location = useLocation();

  // Temporary until the whole app is responsive
  const isMobile = useScreenSize();

  if (isMobile) {
    return <MobileMessage />;
  }

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route
          path="/package-submission-form"
          element={
            <PageWrapper>
              <PackageSubmissionForm />
            </PageWrapper>
          }
        />
        <Route path="/" element={<Home />} />
        <Route
          path="/package-config"
          element={
            <PageWrapper>
              <PackageConfig />
            </PageWrapper>
          }
        />
        <Route
          path="/user-profile"
          element={
            <PageWrapper>
              <UserProfile />
            </PageWrapper>
          }
        />
        <Route
          path="/package-manager"
          element={
            <PageWrapper>
              <PackageManager />
            </PageWrapper>
          }
        />
        <Route
          path="/purchase-manager"
          element={
            <PageWrapper>
              <PurchasesManager />
            </PageWrapper>
          }
        />
      </Routes>
    </AnimatePresence>
  );
};

export default AppRoutes;
