import React, { useRef, useState, useMemo, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Avatar,
  Grid,
  Paper,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useAuth } from "../../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
// import Pay from "@Pro-Stack-LLC/spm-shared/lib/sdk/pay";
import User from "@Pro-Stack-LLC/spm-shared/lib/sdk/user";
import { IUser } from "@Pro-Stack-LLC/spm-shared/lib/models/user";

const RightArrow = () => <ArrowForwardIosIcon sx={{ height: "12px" }} />;

const commonBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  mb: 1,
  height: "50px",
  borderBottom: "1px solid black",
  cursor: "pointer",
};

interface RequestConfig {
  token?: string;
  pagination?: {
    page: number;
    limit: number;
  };
}

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const UserProfile: React.FC = () => {
  let query = useQuery();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [userName, setUserName] = useState<string>("");
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  const { token, logout, setUserProfile } = useAuth();
  const navigate = useNavigate();

  const config: RequestConfig = useMemo(
    () => ({
      token: token,
      pagination: {
        page: 0,
        limit: 10,
      },
    }),
    [token]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await User.getCurrent(config);
        if (response.errors.length === 0) {
          if (response.data.avatar) {
            setAvatarSrc(response.data.avatar);
          }
          if (response.data.username) {
            setUserName(response.data.username);
          }
          setUserProfile(response.data);
        } else {
          console.error(response.errors.join(", "));
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [config, setUserProfile]);

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  const handleImageUpload = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    if (query.get("stripe") === "success") {
      console.log("Stripe account created successfully");
    }
  }, [query]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "ml_default");
      setImageLoading(true);

      try {
        const response = await fetch(
          `https://api.cloudinary.com/v1_1/dddtme8n5/image/upload`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          const data = await response.json();
          const imageUrl = data.secure_url;
          const avatarConfig: Partial<IUser> = {
            avatar: imageUrl,
          };

          try {
            const updateResponse = await User.update(avatarConfig, config);
            if (updateResponse.errors.length === 0) {
              setUserProfile(updateResponse.data);
            } else {
              console.error(
                "Avatar update failed with error:",
                updateResponse.errors.join(", ")
              );
            }
          } catch (error) {
            console.log("An error occurred uploading the user's image:", error);
          }
          setAvatarSrc(imageUrl);
        } else {
          console.error("Failed upload:", response.statusText);
        }
      } catch (error) {
        console.error("Error upload:", error);
      } finally {
        setImageLoading(false);
      }
    }
  };

  const handleOpenLogoutDialog = () => {
    setLogoutDialogOpen(true);
  };

  const handleCloseLogoutDialog = () => {
    setLogoutDialogOpen(false);
  };

  const handleConfirmLogout = () => {
    handleCloseLogoutDialog();
    logout();
  };

  return (
    <Box
      sx={{
        paddingBottom: "30px",
        flexGrow: 1,
        height: "calc(100vh - 100px)",
        boxSizing: "border-box",
        "& > .MuiGrid-container": {
          height: "100%",
        },
      }}
    >
      {imageLoading && (
        <LinearProgress
          sx={{ width: "100%", position: "absolute", top: 0, left: 0 }}
        />
      )}
      <Grid
        container
        spacing={2}
        sx={{
          height: "100%",
          width: "72%",
          margin: "0 auto",
          overflow: "hidden",
        }}
      >
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          <Paper
            elevation={3}
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              border: "solid 0.5px #000000",
              boxShadow: "none",
            }}
          >
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />

            <Avatar
              sx={{
                width: 120,
                height: 120,
                mb: 2,
                mt: 2,
                cursor: "pointer",
                position: "relative",
              }}
              src={avatarSrc || ""}
              onClick={handleImageUpload}
            >
              {!avatarSrc && !imageLoading && (
                <AccountCircleIcon sx={{ width: 120, height: 120 }} />
              )}
            </Avatar>

            <Typography sx={{ mb: 1, textTransform: "none", color: "#000000" }}>
              {userName}
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: "auto",
                gap: 1,
              }}
            >
              {/* <LoadingButton
                loading={loading}
                variant="outlined"
                color="error"
                onClick={handleStripeCreateAccount}
              >
                Cashout with Stripe
              </LoadingButton> */}

              <Button
                variant="contained"
                onClick={handleOpenLogoutDialog}
                sx={{ textTransform: "none" }}
              >
                Sign Out
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: "100%",
              border: "solid 0.5px #000000",
              boxShadow: "none",
            }}
          >
            <Box
              onClick={() => handleNavigation("/package-manager")}
              sx={commonBoxStyle}
            >
              <Typography>Packages</Typography>
              <RightArrow />
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={logoutDialogOpen}
        onClose={handleCloseLogoutDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">{"Leaving So Soon?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Logging out will close your session, but we’ll be here when you come
            back. Do you want to confirm?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleCloseLogoutDialog}
            variant="outlined"
            color="secondary"
          >
            No
          </Button>
          <Button
            onClick={handleConfirmLogout}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserProfile;
