import React, { FC } from "react";
import { Box, Typography, Button, Stack } from "@mui/material";

interface PackageConfigModalProps {
  onBack: () => void;
  onLaunch: () => void;
}

const PackageConfigModal: FC<PackageConfigModalProps> = ({
  onBack,
  onLaunch,
}) => {
  return (
    <Box sx={containerStyle}>
      <Typography
        variant="h6"
        component="h2"
        sx={{ fontWeight: "bold", mb: 2 }}
      >
        Are you sure you're ready to launch your package listing?
      </Typography>
      <Typography variant="body2" sx={{ mb: 4 }}>
        Make sure you completed the steps on the previous page before listing or
        you won't get paid for downloads.
      </Typography>
      <Stack direction="row" spacing={2} justifyContent="center">
        <Button variant="outlined" onClick={onBack}>
          Back
        </Button>
        <Button variant="contained" onClick={onLaunch}>
          Launch it!
        </Button>
      </Stack>
    </Box>
  );
};

export default PackageConfigModal;

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 1,
  p: 0,
  maxWidth: 500,
  textAlign: "center",
  "&:focus-visible": {
    outline: "none",
  },
};
