import React, { useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { trackEvent } from "../../../utils/MixpanelUtil";

import { Box } from "@mui/material";

const faqs = [
  {
    question: "What is the Code Store?",
    content: [
      {
        text: "The Code Store is an online marketplace where iOS developers can buy and sell Swift packages (think open source projects except you get paid).",
        bold: false,
        spacer: false,
      },
      {
        text: "In other words, it’s a website where iOS developers can sell their code to other iOS developers, without having to create an entire backend infrastructure themselves. This enables and incentivizes iOS devs to share more of their code with the community because now they can benefit financially from their contributions.",
        bold: false,
        spacer: true,
      },
      {
        text: "Don't get us wrong, we still believe the open-source community is a tremendous asset, but we also understand that developing and maintaining great code takes time and effort, so we believe developers deserve to be rewarded for their hard work. We hope the Code Store will encourage dramatic growth in the amount of libraries developers are able to share with one another.",
        bold: false,
        spacer: true,
      },
      {
        text: "You can sell any code that can be downloaded via Swift Package Manager (SPM).",
        bold: false,
      },
    ],
  },
  {
    question: "What problem are we aiming to solve?",
    content: [
      {
        text: "Me writing a feature:",
        bold: true,
        spacer: false,
      },
      {
        text: `"Hmm, there's gotta be an open-source library for this."`,
        bold: false,
        spacer: false,
      },
      {
        text: "Quickly checks Google:",
        bold: true,
        spacer: true,
      },
      {
        text: `Nothing.`,
        bold: false,
        spacer: false,
      },
      {
        text: "CocoaPods?:",
        bold: true,
        spacer: true,
      },
      {
        text: `...🙏🏻...please grant me success on this improbable quest.`,
        bold: false,
        spacer: false,
      },
      {
        text: "Scans the search results:",
        bold: true,
        spacer: true,
      },
      {
        text: `"Wait, here's something..."`,
        bold: false,
        spacer: false,
      },
      {
        text: "Checks the repo:",
        bold: true,
        spacer: true,
      },
      {
        text: `"Last updated 5 years ago; written in Objective-C." 😑`,
        bold: false,
        spacer: false,
      },
      {
        text: "Furiously writes the feature from scratch:",
        bold: true,
        spacer: true,
      },
      {
        text: `"Why don't people open source!!? 😡 I bet 10 other developers wrote basically the exact same thing this week!"`,
        bold: false,
        spacer: false,
      },
      {
        text: "Finally finishes 3 hours later:",
        bold: true,
        spacer: true,
      },
      {
        text: `"Dang that actually looks pretty good."`,
        bold: false,
        spacer: false,
      },
      {
        text: "Considers open-sourcing the new library on GitHub:",
        bold: true,
        spacer: true,
      },
      {
        text: `🤔 "...Nah, too much work." ¯\\_(ツ)_/¯`,
        bold: false,
        spacer: false,
      },
      {
        text: "It's obvious that reusable libraries are pivotal in the software development community, solving common (and oftentimes complex) problems and saving countless dev hours.",
        bold: false,
        spacer: true,
      },
      {
        text: "But even though almost every iOS developer has benefited from the charitable contributions of the open source community (just imagine a world without libraries like Alamofire or Kingfisher), we can probably relate to the scene above more than we care to admit.",
        bold: false,
        spacer: true,
      },
      {
        text: "This reality echoes a common refrain within the developer: maintaining an open source project demands significant time and resources. Pleas for donations serve as a testament to this challenge.",
        bold: false,
        spacer: true,
      },
      {
        text: "In short, there are two problems we aim to solve:",
        bold: false,
        spacer: true,
      },
      {
        text: "(1) The limited amount of quality open source libraries.",
        bold: false,
        spacer: true,
      },
      {
        text: "(2) The lack of opportunity for iOS developers to sell their code.",
        bold: false,
        spacer: true,
      },
    ],
  },
  {
    question: "When will the store launch?",
    content: [
      {
        text: "You can start listing your packages immediately to prepare for the official marketplace launch on July 1st.",
        bold: false,
        spacer: false,
      },
    ],
  },
  {
    question: "How can I best prepare for the launch and gain an advantage?",
    content: [
      {
        text: "There’s a couple things you can do to prepare for the launch of the Code Store, which will help you secure a first-mover advantage in the marketplace.",
        bold: false,
        spacer: false,
      },
      {
        text: "1. Add your email to our subscriber list:",
        bold: true,
        spacer: true,
      },
      {
        text: "We will send out important updates – such as when the marketplace goes live – to our subscribers. You can subscribe on the home page of the Code Store’s website.",
        bold: false,
        spacer: true,
      },
      {
        text: "2. Follow our LinkedIn Page:",
        bold: true,
        spacer: true,
      },
      {
        text: "We launched a brand new LinkedIn page to share daily updates with our followers. We recommend following to stay up-to-date on any news.",
        bold: false,
        spacer: true,
      },
      {
        text: "3. Start creating Swift packages:",
        bold: true,
        spacer: true,
      },
      {
        text: "As an iOS developer, we know you’ve written a lot of code, and we also know a lot of it could probably help other developers. One of the best ways to prepare for our launch and secure your spot as a dominate Code Store seller is to start compiling your best work into Swift packages so you can list it on our store and start making recurring income.",
        bold: false,
        spacer: true,
      },
      {
        text: "You can sell something as small as a custom UIView or helper class, or as large as an entire library. Any piece of code that you think will save another iOS developer time would be perfect to sell on the Code Store.",
        bold: false,
        spacer: true,
      },
    ],
  },
  {
    question: "How will my Swift package earn money?",
    content: [
      {
        text: "We will offer several pricing models you can choose from to earn money on the Swift packages that you sell, and you can even choose more than one:",
        bold: false,
        spacer: false,
      },
      {
        text: "1. Pay-As-You-Go:",
        bold: true,
        spacer: true,
      },
      {
        text: "This is our recommended pricing model, which charges buyers based on the number of downloads their app gets (that uses your package). You can even implement a free tier before charging (e.g. 100 free downloads). This ensures iOS devs can try your package for free, continue using it in a way that directly scales with their app’s profits, and provides substantial recurring income for you as your package is continually used in the wild.",
        bold: false,
        spacer: true,
      },
      {
        text: "2. Fixed Price:",
        bold: true,
        spacer: true,
      },
      {
        text: "The second way to earn money is by selling your package for a one-time price. Buyers pay you once upfront for unlimited use of your code in their apps.",
        bold: false,
        spacer: true,
      },
      {
        text: "To make sure you get rewarded for your work and avoid normalizing free stuff (which we think the App Store has struggled against), we will likely require a minimum fixed price of at least $1 in addition to any other payment model you implement.",
        bold: false,
        spacer: true,
      },
      {
        text: "3. Subscription:",
        bold: true,
        spacer: true,
      },
      {
        text: "The last pricing model you can choose to use is a subscription model, which charges buyers a weekly, monthly, or yearly price for unlimited to use of your package. This could be a great option if you think your package deserves to be rewarded based on the duration its used, as opposed to the strict number of downloads a buyer’s app gets.",
        bold: false,
        spacer: true,
      },
    ],
  },
];

const LandingPageFAQs: React.FC = () => {
  useEffect(() => {
    trackEvent("promo_faqs_page_entry");
  });

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 768,
        mx: "auto",
        my: 7,
        pl: 3,
        pr: 3,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          mb: 2,
          textAlign: "left",
          fontFamily: "Inter",
          fontSize: "1.625rem",
          fontWeight: "600",
        }}
      >
        FAQ
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion
          key={index}
          sx={{
            border: "1px solid #C6C6C6",
            boxShadow: "none",
            "&:before": { display: "none" },
            borderRadius: "0.3125rem",
            mb: 2.25,
          }}
          TransitionProps={{ timeout: 0 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "1rem",
                fontWeight: "600",
                textAlign: "left",
              }}
            >
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {faq.content.map((item, idx) => (
              <Typography
                key={idx}
                sx={{
                  textAlign: "left",
                  fontFamily: "Inter",
                  fontSize: "0.875rem",
                  fontWeight: item.bold ? "600" : "300",
                  marginTop: item.spacer ? "1rem" : "0rem",
                }}
              >
                {item.text}
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default LandingPageFAQs;
