import React from "react";
import { Box, Typography } from "@mui/material";

const Footer: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "var(--footer-height)",
        backgroundColor: "var(--spm-header-footer-color)",
        paddingX: {
          xs: "8%",
          sm: "5%",
          md: "15%",
          lg: "15%",
          xl: "15%",
        },
        borderTop: "0.25px solid rgba(163, 163, 163, 0.25)",
      }}
    >
      <Typography
        sx={{
          fontSize: "0.75rem",
          fontWeight: "300",
          fontFamily: "Inter",
          textAlign: "left",
          width: "400px",
        }}
      >
        Copyright © 2024 Code Store, LLC
      </Typography>
      <Typography
        sx={{
          fontSize: "0.75rem",
          fontWeight: "300",
          fontFamily: "Inter",
          textAlign: "right",
        }}
      >
        Created by Eric Solberg, Steven Wright, and Charlie Arcodia of Pro Stack
        Development, LLC
      </Typography>
    </Box>
  );
};

export default Footer;
